import { IdNamePair } from '@app/controls/TypeaheadControl';
import { insightIds } from './insightIds';

export class NudgeData {
    static KeywordsMapping = [
        { id: 'Empowerment', name: 'Empowerment' },
        { id: 'Confidence', name: 'Confidence' },
        { id: 'Passion', name: 'Passion' },
        { id: 'Effective', name: 'Effective' }
    ];

    static MentionedKeywordsMapping = [
        { id: 'PROJECT', name: 'Project' },
        { id: 'BRAINSTORM', name: 'Brainstorm' },
        { id: 'MILESTONE', name: 'Milestone' },
        { id: 'STORE', name: 'Store' },
        { id: 'SALES', name: 'Sales' },
        { id: 'PHONE', name: 'Phone' },
        { id: 'OFFICE', name: 'Office' },
        { id: 'PRESENTATION', name: 'Presentation' },
        { id: 'DESK', name: 'Desk' },
        { id: 'MEETING', name: 'Meeting' },
        { id: 'MEET', name: 'Meet' },
        { id: 'TEAM', name: 'Team' },
        { id: 'TEAM_MEETING', name: 'Team Meeting' },
        { id: 'MANAGER', name: 'Manager' },
        { id: 'LEADER', name: 'Leader' },
        { id: 'PROMOTION', name: 'Promotion' },
        { id: 'ONE_ON_ONE', name: 'One on One' },
        { id: 'EMAIL', name: 'Email' },
        { id: 'CUSTOMER', name: 'Customer' },
        { id: 'HOURS', name: 'Hours' },
        { id: 'DETACH', name: 'Detach' },
        { id: 'PHONE_CALL', name: 'Phone Call' },
        { id: 'CUSTOMER_CALL', name: 'Customer Call' },
        { id: 'TOUGH_CALL', name: 'Tough Call' },
        { id: 'DIFFICULT_CALL', name: 'Difficult Call' },
        { id: 'WEEKEND', name: 'Weekend' },
        { id: 'HEALTHCARE', name: 'Healthcare' },
        { id: 'CLINICAL_ONLY', name: 'Clinical Only' }
    ];

    static InteractionTargetMapping = [
        { id: 'TEAM', name: 'Team' },
        { id: 'COLLEAGUE', name: 'Colleague' },
        { id: 'SUPERVISOR', name: 'Supervisor' },
        { id: 'REPORTS', name: 'Report' },
        { id: 'CUSTOMER', name: 'Customer' },
        { id: 'LEADER', name: 'Leader' },
        { id: 'PATIENT', name: 'Patient' }
    ];

    static InteractionModalityMapping = [
        { id: 'PHONE_CALL', name: 'Phone call' },
        { id: 'IN_PERSON', name: 'In person' },
        { id: 'VIDEO_CALL', name: 'Video call' },
        { id: 'MESSAGING_APP', name: 'Messaging app' },
        { id: 'EMAIL', name: 'Email' },
        { id: 'TEXT', name: 'Text' }
    ];

    static InteractionTypeMapping = [
        { id: 'TEAM_MEETING', name: 'Team Meeting' },
        { id: 'ONE_ON_ONE', name: '1:1' },
        { id: 'MEETING', name: 'Meeting' },
        { id: 'ALL_HANDS', name: 'All Hands' },
        { id: 'NO_INTERACTIONS', name: 'No Interactions' }
    ];

    static InteractionLocationMapping = [
        { id: 'STORE', name: 'Store' },
        { id: 'OFFICE', name: 'Office' },
        { id: 'OTHER_LOCATION', name: 'Other' },
        { id: 'HOME', name: 'Home' },
        { id: 'CALLCENTER', name: 'Call Center' },
        { id: 'WORKSITE', name: 'Worksite' },
        { id: 'WAREHOUSE', name: 'Warehouse' }
    ];

    static BehaviorMapping = [
        { id: 'act_feedback', name: 'Act on feedback' },
        { id: 'advocate_others', name: 'Advocate for others' },
        { id: 'advocate_needs', name: 'Advocate for your work needs' },
        { id: 'align_goals', name: 'Align personal, team, and company goals' },
        { id: 'open_mind', name: 'Approach change with an open mind' },
        { id: 'avoid_decision_biases', name: 'Avoid common decision biases' },
        { id: 'avoid_distractions', name: 'Avoid distractions' },
        {
            id: 'aware_emotions',
            name: 'Be aware of your emotions and their impact on others'
        },
        { id: 'supportive_responsive', name: 'Be supportive and responsive' },
        { id: 'true_to_values', name: 'Be true to your values' },
        { id: 'build_relationships', name: 'Build meaningful relationships' },
        { id: 'build_self_awareness', name: 'Build self-awareness' },
        {
            id: 'celebrate_customer_initaitives',
            name: 'Celebrate customer-focused initiatives'
        },
        { id: 'celebrate_wins', name: 'Celebrate shared wins' },
        { id: 'champion_change', name: 'Champion change efforts' },
        { id: 'champion_experimentation', name: 'Champion experimentation' },
        { id: 'clarify_expectations', name: 'Clarify expectations for work' },
        {
            id: 'team_norms_collaboration',
            name: 'Co-create team norms for collaboration'
        },
        {
            id: 'communicate_progress',
            name: 'Communicate progress toward goals'
        },
        { id: 'mutual_respect', name: 'Create a culture of mutual respect' },
        { id: 'path_toward_goals', name: 'Create a path toward goals' },
        {
            id: 'systems_share_info',
            name: 'Create systems and processes to share information'
        },
        {
            id: 'systems_for_feedback',
            name: 'Create systems and processes to share feedback'
        },

        { id: 'define_worklife', name: 'Define your ideal work-life balance' },
        { id: 'delight_customers', name: 'Delight your customers' },
        { id: 'quality_work', name: 'Deliver high-quality work output' },
        {
            id: 'deliver_solutions',
            name: 'Deliver valuable, impactful solutions'
        },
        { id: 'demonstrate_humility', name: 'Demonstrate humility' },
        { id: 'self_awareness', name: 'Demonstrate self-awareness' },
        { id: 'develop_backup_plan', name: 'Develop a backup plan' },
        {
            id: 'crossfunctional_goals',
            name: 'Develop and socialize cross-functional goals'
        },
        { id: 'develop_new_skills', name: 'Develop new skills and abilities' },
        { id: 'disconnect', name: 'Disconnect during off-hours' },
        {
            id: 'empower_best_work',
            name: 'Empower others to do their best work'
        },

        { id: 'healthy_debate', name: 'Encourage healthy debate' },
        {
            id: 'encourage_learning_mindset',
            name: 'Encourage learning mindset'
        },
        {
            id: 'establish_roles',
            name: 'Establish clear roles and responsibilities'
        },

        { id: 'evaluate_progress', name: 'Evaluate progress toward goals' },
        {
            id: 'explain_decisions',
            name: 'Explain how and why decisions are made'
        },
        { id: 'big_ideas', name: 'Explore big ideas' },
        { id: 'express_gratitude', name: 'Express gratitude' },
        { id: 'cope_stress', name: 'Find healthy ways to cope with stress' },
        {
            id: 'input_inform_decisions',
            name: 'Gather input to inform decisions'
        },
        { id: 'give_opportunities', name: 'Give everyone opportunities' },
        {
            id: 'help_others_stretch_goals',
            name: 'Help others set stretch goals'
        },
        {
            id: 'hold_accountable',
            name: 'Hold yourself and others accountable'
        },

        {
            id: 'collaborate_aross_teams',
            name: 'Identify opportunities to collaborate across teams'
        },
        {
            id: 'implement_change',
            name: 'Identify opportunities to implement change'
        },
        {
            id: 'include_other_teams',
            name: 'Include stakeholders from other teams'
        },
        {
            id: 'incorporate_perspectives',
            name: 'Incorporate diverse perspectives'
        },

        { id: 'healthy_work_habits', name: 'Invest in healthy work habits' },

        { id: 'learn_customer_feedback', name: 'Learn from customer feedback' },
        { id: 'learn_from_mistakes', name: 'Learn from mistakes' },
        { id: 'let_go_control', name: "Let go of what you can't control" },
        { id: 'leverage_resources', name: 'Leverage available resources' },
        {
            id: 'leverage_expertise',
            name: "Leverage others' expertise to accomplish goals"
        },
        { id: 'listen_perspectives', name: 'Listen to diverse perspectives' },
        { id: 'connect_ideas', name: 'Make connections between ideas' },
        { id: 'customer_decisions', name: 'Make customer focused decisions' },
        { id: 'navigate_conflict', name: 'Navigate conflict respectfully' },
        { id: 'share_ideas', name: 'Openly share ideas and perspectives' },
        { id: 'overcome_obstacles', name: 'Overcome obstacles' },
        { id: 'fair_outcomes', name: 'Prioritize fair outcomes' },
        { id: 'prioritize_impact_work', name: 'Prioritize high-impact work' },
        {
            id: 'prioritize_mission_work',
            name: 'Prioritize mission-critical work'
        },
        { id: 'prioritize_safety', name: 'Prioritize safety' },
        { id: 'include_others', name: 'Proactively include others' },
        {
            id: 'constructive_feedback',
            name: 'Provide constructive feedback to others'
        },

        {
            id: 'support_development',
            name: 'Provide emotional and tangible support for development'
        },
        { id: 'question_statusquo', name: 'Question the status quo' },
        { id: 'inspiring_vision', name: 'Rally around an inspiring vision' },
        { id: 'recognize_others', name: "Recognize others' work" },
        { id: 'reframe_challenges', name: 'Reframe challenges' },
        { id: 'remove_barriers', name: 'Remove barriers to progress' },
        { id: 'respond_with_empathy', name: 'Respond with empathy' },
        { id: 'seek_feedback_learn', name: 'Seek feedback' },
        { id: 'achievable_goals', name: 'Set clear and achievable goals' },
        {
            id: 'processes_task_easier',
            name: 'Set up processes to make tasks easier'
        },
        {
            id: 'share_good_bad',
            name: 'Share important information—good or bad'
        },
        { id: 'share_knowledge', name: 'Share knowledge and expertise' },
        { id: 'share_true_self', name: 'Share your true self' },
        { id: 'own_outcomes', name: 'Take ownership of work outcomes' },
        {
            id: 'stakeholder_needs',
            name: 'Take stakeholder needs and priorities into account'
        },
        {
            id: 'tie_bigger_picture',
            name: 'Tie daily tasks to the bigger picture'
        },
        { id: 'try_new_ways', name: 'Try new ways of doing things' },
        { id: 'work_impact', name: 'Understand the impact of your work' },
        {
            id: 'understand_root_cause',
            name: 'Understand the root cause of issue or problem'
        },
        { id: 'data_decisionmaking', name: 'Use data to make decisions' },
        { id: 'inclusive_language', name: 'Use inclusive language' },
        {
            id: 'info_inform_decisions',
            name: 'Use new information to inform decisions'
        },
        { id: 'use_time_effectively', name: 'Use time effectively' },
        {
            id: 'cost_benefit_analysis',
            name: 'Weigh costs, benefits, and risks'
        }
    ];

    static InsightIdsMapping: IdNamePair[] = insightIds.map((id) => ({
        id,
        name: id
    }));

    static CatalystMapping = [
        { id: 'new_team_or_reorg', name: 'New Team or Reorg' },
        { id: 'low_morale', name: 'Low Morale' },
        { id: 'burnout_risk', name: 'Burnout Risk' },
        { id: 'prepare_for_change', name: 'Prepare For Change' },
        { id: 'interpersonal_conflict', name: 'Interpersonal Conflict' },
        { id: 'attrition_risk', name: 'Attrition Risk' },
        {
            id: 'transition_to_hybrid_teamwork',
            name: 'Transition to Hybrid Teamwork'
        },
        { id: 'managing_hybrid_teams', name: 'Managing Hybrid Teams' },
        { id: 'lack_of_belonging', name: 'Lack of Belonging' },
        { id: 'intentional_meetings', name: 'Intentional Meetings' },
        { id: 'effective_meetings', name: 'Effective Meetings' },
        { id: 'fresh_starts', name: 'Fresh Starts' },
        { id: 'performance_review', name: 'Performance Review' },
        { id: 'career_development', name: 'Career Development' }
    ];

    static CatalogResourceMapping = [
        { id: 'time_off_request', name: 'Time off request platform' },
        { id: 'effective_meetings', name: 'Effective meetings resource' },
        { id: 'career_development', name: 'Career development tools' },
        {
            id: 'learning_management_system',
            name: 'Learning management system'
        },
        {
            id: 'performance_evaluation',
            name: 'Performance evaluation process'
        },
        { id: 'inclusive_meetings', name: 'Inclusive meetings resource' },
        {
            id: 'unconscious_bias',
            name: 'Mitigating unconscious bias resource'
        },
        { id: 'allyship', name: 'Allyship' },
        { id: 'requesting_feedback', name: 'Requesting feedback platform' },
        { id: 'public_recognition', name: 'Public recognition platform' },
        { id: 'personal_development', name: 'Personal development plan' },
        { id: 'mentorship', name: 'Mentorship resource' },
        { id: 'company_benefits', name: 'Company benefits portal' },
        { id: 'total_rewards', name: 'Total rewards information' }
    ];
}
